.ter-footer{
  border-top: 0px;
  font-family: 'Open Sans';
}
.ter-footer__text {
  padding-right: 28px;
}
.ter-footer__text {
  font-size: 12px;
  margin-bottom: 9px;
  padding-right: 10px;
  font-family: 'Open Sans';
  color: #000000;
}
.ter-footer__link {
  height: 30px !important;
  color: #005F9E;
  width: 190px !important;
  font-weight: 500;
}
.ter-footer__secondary-link{
  font-weight: 500;
}
a.ter-footer__legal-stuff-link{
  color: #005F9E;
}
.ter-footer > .ter-footer__middle-section-wrapper:nth-child(2) {
  padding-bottom: 25px;
}
.ter-footer__link-containers section:nth-child(1):before, .ter-footer__link-containers section:nth-child(2):before {
  font-weight: bold;
  color: #363545;
  margin-bottom: 20px;
  font-size: 16px;
  display: block;
}

.footer {
  background-color: #f3f3f7;
  padding: 100px 0 10px;
}
.footer hr {
  border-top: 1px #bfbfc6 solid;
  padding-top: 0;
  padding-bottom: 0;
}
#tp-ft-hr {
  margin-bottom: 40px;
}
.footer h6 {
  margin-bottom: 10px;
  color: #363545;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.footer ul {
  font-size: 14px;
  font-weight: 300;
  color: #363545;
  margin: 14px 0 0;
  padding: 0;
  list-style: none;
}
.footer li {
  padding: 0 0 14px;
}

.footer-corp-links {
  font-size: 12px !important;
}
.footer-corp-links li {
  display: inline-block;
  margin: 0 20px;
}

.footer-corp-links:first-child li {
  margin-left: 0;
}

.footer-padding {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.footer-link a {
  font-size: 12px;
  font-weight: 300 !important;
  color: #363545 !important;
  margin: 14px 0 0;
  padding: 2px;
  list-style: none;
}

.footer-corp-links li a {
  color: #363545 !important;
  margin: 14px 0 0;
  padding: 2px;
  list-style: none;
}

@media screen and (max-width:600px) {
  .ter-footer__legal-stuff-link {
    display: block;
    margin-right: 16px !important;
    margin-bottom: 16px !important;
  }

  .ter-footer__link {
    height: 20px !important;
  }
}


@media screen and (max-width: 400px)
{.ter-footer__link {
    width: 160px !important;
}}