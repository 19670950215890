.ter-list-feat-seven {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ter-list-feat-main {
  padding: 96px calc((100vw - 1200px) / 2);
  margin: auto;
  position: relative;
  overflow: hidden;
}

.thank_you_header {
  font-family: "Open Sans" !important;
}

.thank_you_header {
  opacity: 1;
  transition: opacity 0.2s;
  margin-bottom: 0px;
}

.header_Data {
  color: #005f9e;
}

.ter-variable-card {
  position: relative;
  width: 288px;
  margin: 16px 16px 32px;
  z-index: 1;
  background: transparent;
  display: flex;
  flex-direction: column;
  box-shadow: none;
}

.luna-video-feat__thumbnail-container,
.luna-video-feat .variable-content-img-container {
  position: relative;
}

.ter-list-feat-main .ter-variable-card .ter-variable-card__header,
.ter-list-feat-main .ter-variable-card .ter-variable-card__text,
.ter-list-feat-main .ter-variable-card .ter-button,
.ter-list-feat-main .ter-variable-card .luna-video-feat__thumbnail {
  opacity: 1;
  transition: opacity 0.2s;
  color: #363545;
  font-size: 20px;
  margin-bottom: 30px;
  font-family: "Open Sans" !important;
}

.ter-variable-card img {
  flex: 1 1;
  padding: 0;
}

.luna-video-feat__thumbnail,
.luna-video-feat .variable-content-img {
  background-size: cover;
  height: 162px;
  flex: 1 1;
  vertical-align: top;
  width: 288px;
  margin: auto;
  transition: 0.2s;
}

.ter-variable-card__body {
  font-size: 16px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 35px 0 0 0;
}

.luna-video-feat__video-wrapper--default {
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.luna-video-feat__video-wrapper {
  position: relative;
  transition: 0.3s;
  transform: translateY(50px);
  z-index: 100;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-family: "Open Sans" !important;
  line-height: 1.2;
  font-weight: 800;
  color: #262533;
  display: block;
  margin: 0 0 0.67em 0;
  color: #363545;
}
h1,
h2,
h3,
h4,
h5,
h6, label, input, select, textarea, a, input[type=submit], p {
  font-family: 'Open Sans' !important;
}
.ter-list-feat-main .ter-variable-card .ter-variable-card__header,
.ter-list-feat-main .ter-variable-card .ter-variable-card__text,
.ter-list-feat-main .ter-variable-card .ter-button,
.ter-list-feat-main .ter-variable-card .luna-video-feat__thumbnail {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-variable-card__body p {
  flex: 1 1;
  font-size: 16px;
  display: flex;
  font-family: "Open Sans" !important;
  color: #363545;
  width: 40%;
  text-align: center;
}

.ter-hero__header,
.ter-feat__header {
  font-family: "Open Sans" !important;
  font-size: 32px;
}

.justify-center {
  justify-content: center !important;
}

.h-60 {
  height: 60px;
}

.w-60 {
  width: 60px;
}

@media screen and (max-width: 500px) {
  .ter-variable-card__body p {
    flex: 1 1;
    font-size: 16px;
    display: flex;
    font-family: "Open Sans" !important;
    color: #363545;
    width: 80%;
    text-align: center;
  }
}
