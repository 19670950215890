.about_auth {
  padding: 100px 30px;
}

.about_auth h2, .about_auth h3 {
  color: #fff;
  margin: 0 0 8px 0;
  word-break: break-word;
}

.auth_main {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  align-items: center;
}

.auth_name {
  padding-right: 25px;
}

.auth_detail p {
  font-size: 18px;
  line-height: 26px;
}
.auth_detail {
  color: #fff;
}
.auth_detail label {
  color: #fff;
  font-weight: 200;
  font-size: 15px;
  margin-bottom: 20px;
  display: block;
}

.auth_name img {
  width: 100px !important;
  height: 100px;
  border-radius: 50%;
}

.ter-filter-hero {
  &__header-section {
    max-width: 500px;
    padding: 100px 0 100px 100px;

    @media (max-width: 500px) {
      padding: 100px 32px;
    }
  }

  &__header {
    @include header-standard-size;
    margin: 0;
  }

  &__text,
  &__text-holder p {
    font-size: font-size("base");
    padding-top: 12px;
    margin: 0;
  }

  &__dropdown-container {
    margin-left: 100px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @media (max-width: 500px) {
      margin-left: 32px;
    }
  }

  &__checkbox-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__dropdown-label {
    margin-bottom: 0;
    @include benton-medium();
    font-size: font-size("sm");
  }

  .ter-select {
    &__wrapper {
      width: 256px;
      margin-right: 20px;
    }

    @media (max-width: 500px) {
      margin: 0 0 20px 0 !important;
    }

    &__options-list {
      top: 50px;
    }
  }

  .ter-checkbox {
    margin: 0 20px;
    -ms-flex-item-align: end;
    align-self: flex-end;

    &__label {
      font-size: font-size("micro");
      padding-top: 3px;


      .ter-filter-hero__header {
        font-size: 24px !important;
        margin: 0;
    }


      
   
      @media (max-width: 500px) {
        margin: 0 !important;
        
      }
     
    }
  }
}
