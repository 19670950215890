.ter-feat-four {
  padding: 68px calc((100vw - 1200px) / 2);
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  -webkit-box-sizing: border-box;
  position: relative;

  &__header {
    @include header-standard-size;
    text-align: center;
  }

  &__sub-header,
  &__sub-header-holder p {
    @include subheader-standard-size;
    @include benton-light();
    text-align: center;
  }

  .react-reveal {
    display: -ms-grid;
    display: grid;
  }

  .ter-cta-section {
    @media screen and (min-width: $breakpoint-xs) {
      margin: auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }

    .tera-button {
      margin: spacing("sm") 0 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      @media screen and (min-width: $breakpoint-xs) {
        text-align: center;
        padding-left: spacing("lg");
        padding-right: spacing("lg");
        margin-left: spacing("sm");
        margin-right: spacing("sm");
      }
    }
  }

  &__background-image {
    position: absolute;
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .ter-feat-four {
    &__sub-header,
    &__sub-header-holder p {
      margin-top: 65px;
    }

    .ter-cta-section {
      margin: 130px;
    }
  }
}

.ter-feat-four__sub-header,
.ter-feat-four__sub-header-holder p {
  font-family: "Open Sans" !important;
  font-weight: 300;
}

.banner-section {
  height: 40vh;
}

.banner-section .ter-cta-section {
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .ter-list-feat-mains {
    overflow: auto !important;
  }
  .tera-button {
    margin: 24px auto 0px !important;
  }
  .banner-section {
    height: auto; 
  }
}
