.ter-list-feat-seven {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ter-list-feat-main {
  padding: 96px calc((100vw - 1300px) / 2);
  margin: auto;
  position: relative;
  overflow: hidden;
}

.ter-list-feat-main.paralex-link-main {
  padding: 96px calc((100vw - 1300px) / 2) !important;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.ter-hero__header,
.ter-feat__header {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

.ter-list-feat-main .ter-list-feat-main__header,
.ter-list-feat-main .ter-list-feat-main__sub-header,
.ter-list-feat-main .ter-list-feat-main__sub-header-holder {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-list-feat-main .ter-list-feat-main__header,
.ter-list-feat-main .ter-list-feat-main__sub-header,
.ter-list-feat-main .ter-list-feat-main__sub-header-holder {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-variable-card {
  position: relative;
  width: 288px;
  margin: 16px 16px 32px;
  z-index: 1;
  background: transparent;
  display: flex;
  flex-direction: column;
  box-shadow: none;
}

.parallex-card-image,
.luna-video-feat .variable-content-img-container {
  position: relative;
}

.ter-list-feat-main .ter-variable-card .ter-variable-card__header,
.ter-list-feat-main .ter-variable-card .ter-variable-card__text,
.ter-list-feat-main .ter-variable-card .ter-button,
.ter-list-feat-main .ter-variable-card .card-image {
  opacity: 1;
  transition: opacity 0.2s;
}

// .ter-variable-card img {
//   flex: 1 1;
//   padding: 0;
// }

.card-image,
.luna-video-feat .variable-content-img {
  background-size: cover;
  max-height: 162px;
  flex: 1 1;
  vertical-align: top;
  max-width: 288px;
  margin: auto;
  transition: 0.2s;
}

.ter-variable-card__body {
  font-size: 16px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0 0 0;
}

.luna-video-feat__video-wrapper--default {
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.luna-video-feat__video-wrapper {
  position: relative;
  transition: 0.3s;
  transform: translateY(50px);
  z-index: 100;
}

.ter-list-feat-main .ter-variable-card .ter-variable-card__header,
.ter-list-feat-main .ter-variable-card .ter-variable-card__text,
.ter-list-feat-main .ter-variable-card .ter-button,
.ter-list-feat-main .ter-variable-card .card-image {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-variable-card__body p {
  flex: 1 1;
  font-size: 16px;
  display: flex;
}

.ter-hero__header,
.ter-feat__header {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

.paralex-link-main .ter-card__text {
  color: white ;
  font-weight: 400;
  text-align: center;
}
.card-image-container {
    text-align: center;
}
