.ter-list-feat-main__header{text-align: center;}
.date_news{color: #005f9e;     padding: 3px 0 10px 0;     font-size: 16px;}
.newsec .ter-button--secondary--1, .newsec a.ter-button--secondary--1 {
  background-color: transparent;
  border: 1px solid #0d6aa8;
  color: #0d6aa8 !important;}

  .newsec .ter-button:hover {
    background-color: #0d6aa8;
    border: 1px solid #0d6aa8;
    color: #fff !important;}


.newsec .ter-feat__header{text-align: center; padding-bottom: 20px;}
.newsec{ background: #eaeaef;}
.newsec .ter-button{font-weight: 800;     margin-top: 20px;}
.ter-button, a.ter-button {
  font-size: 16px;
  font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"helvetica neue",helvetica,ubuntu,roboto,noto,"segoe ui",arial,sans-serif;
  font-weight: 400;
  font-feature-settings: "kern" !important;
  -webkit-font-kerning: normal !important;
  font-kerning: normal !important;
  padding: 0 24px;
  height: 48px;
  line-height: calc(48px - (2px));
  border-radius: 0;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.ter-button:hover {
  background-color: #09466f; color:#FFF!important;
}

.ter-list-feat-six {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media screen and (max-width: 900px) {
    display: block;
  }

  &__card {
    padding: 32px;
    margin: 0;
    width: 100%;
    background: #f3f3f7;
    min-width: 200px;
    min-height: 100px;
    vertical-align: center;
    img {
      width: 100%;
    }

    @media screen and (max-width: 900px) {
      margin: 0 auto 32px;
      max-width: 600px;
    }
  }

}
