@import url("../../../assets/fonts/fonts.css");
.home_tab {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
}
.home_tab li button {
  border: 0;
  border-top: .5px solid #7e7e7e;
  color: #7e7e7e;
  background-color: #fff;
  font-size: 16px;
  border-radius: 0;
  text-decoration: none;
  vertical-align: middle;
  transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
  text-align: center;
  padding: 14px 20px;
  cursor: pointer;
  outline: none;
  font-family: "Open Sans","Segoe UI";
  font-weight: 500;
  max-width: 200px;
  word-break: break-word;
  height: 100%;
  white-space: inherit;
}
.home_tab button:hover {
  background-color: #f9f9fb;
}
.home_tab button.active {
  background-color: #f9f9fb;
  border-top: 4px solid #0067b5;
  color: #0067b5;
  margin-top: -2px;
}
.home_tab button:focus {
  outline: none;
}
.home_tab_section {
  background: white;
  margin-top: -2px;
}
.tabcontent {
  display: none;
  padding: 100px 30px;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  animation: fadeEffect 1s;
  position: relative;
}
@keyframes fadeEffect {
  from {
    opacity: 0;
    top: 50px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}
.home_tabs_container {
  padding-top: 100px;
}
.header_home_tabs {
  max-width: 1055px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 30px;
}

.header_home_tabs h2 {
  font-family: "Open Sans", "Segoe UI";
  font-weight: 900;
  font-size: 34px;
}
.header_home_tabs p {
  font-size: 24px;
  font-family: "Open Sans", "Segoe UI";
  font-weight: 400;
  margin: 0 0 1.5em 0;
  color: #363545;
}

.imgResponsive {
  max-width: 100%;
}
.tab_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tab_left_photo {
  width: 46%;
}
.tab_right_content {
  width: 50%;
}
.tab_right_content h3 {
  color: #363545;
  line-height: 1.2;
  font-family: "Open Sans", "Segoe UI";
  font-weight: 900;
  font-size: 34px;
  margin: 0;
}
.tab_right_content p {
  color: #363545;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 20px;
  line-height: 1.6;
  margin: 40px 0px 36px 0px!important;
}

.blue_btn {
  background-color: #005f9e;
  color: #fff;
  padding-right: 30px;
  padding-left: 30px;
  font-weight: 400;
  font-family: "Open Sans", "Segoe UI";
  font-size: 16px;
  height: 48px;
  line-height: calc(48px - (2px));
  text-align: center;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-decoration: none;
}
.blue_btn:hover {
  background-color: #0b5687;
  text-decoration: none;
  color: #fff;
}

@media (max-width: 899px) {
  .tab_body {
    flex-wrap: wrap;
  }
  .tab_left_photo {
    width: 100%;
    margin-bottom: 45px;
  }
  .tab_right_content {
    width: 100%;
  }
  .home_tab {
    flex-wrap: wrap;
  }
  .home_tab li {
    width: 100%;
  }
  .home_tab li button {
    width: 100%;
  }
  .tabcontent {
    padding: 30px;
  }
  .blue_btn {
    width: max-content;
  }
}

@media (max-width: 500px) {
  .home_tab {
    padding: 0px 30px;
    padding-bottom: 8px;
  }
  .home_tabs_container{
    padding-top: 0px;
  }
}

