.ter-list-feat__header {
  margin-left: 30px;
  font-size: 28px;
  font-family: "Benton Sans W4 Black", arial, sans-serif !important;
  text-align: left;
  font-weight: 500 !important;
}

.podcast_section iframe {
  height: 150px !important;
}

.content_inner p {
  font-size: 18px !important;
  line-height: 1.5 !important;
}

.hr {
  width: 100%;
  height: 2px !important;
  background-color: #e8e8e8 !important;
  border: 0;
}

.podcast_box {
  display: flex;
  align-items: center;
}

.pod_cast_player {
  width: 400px;
  padding-right: 30px;
  margin: 0px !important;
}

.pod_cast_detail {
  width: calc(100% - 400px);
}

@media (max-width: 500px) {
  .podcast_box {
    flex-wrap: wrap;
  }
  .pod_cast_player {
    width: 100%;
    padding-right: 0px;
  }
  .pod_cast_detail {
    width: 100%;
  }
}
