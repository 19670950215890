@import "~bootstrap/scss/bootstrap";
@import "../../assets/global-styles/terra.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  min-width: 100%;
  overflow-x: hidden;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: 'Open Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  padding-top: 148px;
  background: #f9f9fb;

  min-height: 100vh;
  // display: grid;
  -webkit-align-items: start;
  align-items: start;
  overflow: hidden;
}

.app .ter-navbar__fullscreen-dark-backer {
  top: 0;
}
.ter-card__body p {
  font-size: 16px !important;
  color: #333243 !important;
}
.ter-card__header {
  color: #333243 !important;
}
//component fixes
@media only screen and (min-width: 900px) {
  .ter-hero-one__content-container {
    min-width: 700px;
  }
}

.ter-utility-nav__page-title {
  border-right: none !important;
}

.ter-filter-hero--default {
  background-color: #002d55;
}

.ter-filter-hero--default h1,
.ter-filter-hero--default h2,
.ter-filter-hero--default h3,
.ter-filter-hero--default p {
  color: white !important;
}
@media only screen and (min-width: 160px) and (max-width: 400px) {
  .App {
    width: 100% !important;
    padding-top: 81px;
  }
}
.ter-card__image {
  height: 250px;
}
.ter-card__image img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
}
.custom-feat .ter-list-feat-main__header {
  margin-bottom: 75px;
}
.paralex-link-main .ter-list-feat-main__header {
  margin-bottom: 75px;
}

.content_inner h1 {
  font-size: 30px !important;
}
.content_inner h2 {
  font-size: 28px !important;
  text-align: left;
}
.content_inner h3 {
  font-size: 26px !important;
}
.content_inner h4 {
  font-size: 24px !important;
}
.content_inner h5 {
  font-size: 22px !important;
}
.content_inner h6 {
  font-size: 20px !important;
}

.content_inner a {
  color: #005F9E;
}

.ter-feat-one__header {
  font-size: 26px !important;
  font-weight: 800 !important;
  color: rgb(54, 53, 69) !important;
  font-family: "Open Sans" !important;
}

.ter-feat-one__text-holder,
.tab_right_content p,
.ter-feat6__quote-text,
.ter-feat-one__text,
.ter-card__text {
  font-family: "Open Sans" !important;
  font-size: 20px !important;
}
.ter-feat-two-b .ter-icon-list-item__header {
  font-family: "Open Sans" !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #363545 !important;
}

.ter-feat-two-b .ter-icon {
  font-size: 18px !important;
}
// .ter-feat-two-b figure, .ter-feat-two-b aside {
//   margin-top: -10%;
// }

.ter-feat-two-b--theme-2 .ter-icon {
  color: #363545 !important;
}

.ter-feat-one--theme-1 {
  background-color: white;
}

.ter-text-white {
  color: white !important;
}

.ter-feat6__quote-icon {
  padding-right: 10px;
}
// .ter-feat6__quote-text::after {
//   content: '\"';
// }

// .ter-feat6__quote-text::after {
//   content: url("../../assets/icon/quote.svg");
//   height: 22px;
//   padding-left: 10px;
// }

// .ter-feat6--theme-4 .ter-feat6__quote-text::after {
//   content: url("../../assets/icon/quote-white.svg");
//   height: 22px;
//   padding-left: 10px;
// }

// .ter-feat6--theme-5 .ter-feat6__quote-text::after {
//   content: url("../../assets/icon/quote-white.svg");
//   height: 22px;
//   padding-left: 10px;
// }

.ter-feat-one__image-wrapper {
  text-align: center;
}

.ter-feat-one__image {
  width: auto !important;
  max-width: 100% !important;
}
@media (max-width: 900px) {
  .app {
    padding-top: 81px;
  }
}
@media (max-width: 500px) {
  .luna-language-selector__region-section {
    height: 445px;
    overflow: scroll;
  }
  .luna-language-selector--default {
    transform: translateY(25px) !important;
  }

  .ter-navbar__fullscreen-dark-backer {
    align-items: flex-start !important;
    padding-top: 0px !important;
  }
  .ter-hero-one__header {
    word-break: break-word;
  }
}
.ter-filter-hero__header-section {
  max-width: 453px;
  padding: 100px 0 100px 60px !important;
}

.ter-filter-hero__dropdown-container {
  margin-left: 60px !important;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 420px) {
  .ter-feat-two-b__image--mobile-header-present {
    padding-top: 200px;
    max-width: 340px !important;
  }
}

@media (max-width: 600px) {
  .ter-feat6 .ter-button {
    word-break: break-word;
    white-space: break-spaces;
    height: auto;
  }
}

.ter-button--secondary--3:hover,
a.ter-button--secondary--3:hover {
  color: #363545 !important;
}

.ter-feat-one--theme-5 h3,
.ter-feat-one--theme-5 h4 {
  color: #f9f9fb !important;
}
.ter-feat-one--theme-4 h3,
.ter-feat-one--theme-4 h4 {
  color: #f9f9fb !important;
}

.ter-button--primary--5:hover,
a.ter-button--primary--5:hover {
  color: #363545 !important;
}

.ter-card__header {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif !important;
  font-weight: bold;  
}

.luna-language-selector {
  overflow-y: hidden !important;
}

.luna-region-card__language--is-active::before {
  top: 10px !important;
}
.ter-feat-one .ter-button {
  white-space: normal;
  word-wrap: break-word;
}
.ter-mega-menu .ter-right-section--is-open {
  overflow-y: auto;
  height: 452px;
}

.luna-region-card__language--is-active::before {
  content: "\2022" !important;
}

.form_box .ter-alert--success {
  margin-top: 50px !important;
}

.ter-alert--success {
  background-color: #d4edda;
  border: solid #5cb85c;
  border-width: 1px 1px 3px;
  font-size: 20px;
  margin: 0px 0px !important;
}

.ter-footer__secondary-link {
  color: #005f9e;
  font-size: 16px;
  font-weight: 600;
}

#webinarForm {
  text-align: left;
}

@media (min-width: 900px) {
  .ter-footer__link-containers {
    width: 650px;
  }

  .ter-footer__link-containers section:nth-child(1) {
    margin-right: 10px;
    width: 250px;
  }

  .ter-footer__link-containers section:nth-child(2) {
    width: 400px;
  }

  .ter-footer__link-containers section:nth-child(2) a {
    width: 100% !important;
  }
}


.ter-list-feat-three .ter-card__image {
  -webkit-flex: none !important;
  flex: none !important;
}
.ter-alert--success {
  max-width: 900px;
  margin: 0 auto !important;
}

.content_inner img {
  max-width: 100%;
}

@media (min-width: 1024px) {
  .banner-section {
    height: auto !important;
  }
}
.blue_btn {
  height: auto;
}

.content_inner div {
  margin: 0 auto;
}

.form_contact_box {
  padding: 15px;
}

.ter-hero-one__content-container p {
  word-wrap: break-word;
}

.content_inner a img {
  padding: 10px 0px;
  width: 100%;
}

.ter-feat-two-b .ter-icon-list-item {
  display: -webkit-box !important;
  padding: 0;
  background: transparent !important;
}

.ter-feat-two-b .ter-icon-list-item .ter-icon {
  margin-top: 4px !important;
}

.ter-feat-two-b__image {
  max-width: 100% !important;
}

@media (max-width: 1070px) {
  .ter-feat-two-b__image-header-present {
    margin-top: 30px !important;
    padding-top: 0px !important;
  }
}
.ter-feat-two-b {
  grid-template-rows: auto !important;
}

// .ter-feat6__quote-text,
// .ter-feat-one__text-holder {
//   word-break: break-word;
// }
.carousel-root div.carousel:nth-child(2) {
  display: none;
}
.ter-list-feat-main__header {
  margin-bottom: 75px !important;
}
.ter-feat-one__text,
.ter-feat-one__text-holder p {
  word-break: break-word;
}
.webinar_video {
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 30px;
}
.webinar_video iframe {
  width: 100%;
  height: 485px;
}
.ter-button--primary--1 {
  color: #fff !important;
}

@media (max-width: 600px) {
  .ter-filter-hero__header-section {
    padding: 100px 32px !important;
  }

  .ter-feat-one__header {
    font-size: 24px !important;
  }

  .ter-feat-one__text-holder a {
    font-size: 18px !important;
  }
  .ter-icon-list-item__content-wrapper {
    padding-right: 40px;
  }
  .ter-list-feat-four__dumpling-container .ter-feat-five__dumpling-wrapper {
    margin: 0px;
  }
  .ter-list-feat-mains {
    padding: 50px 30px !important;
  }
  .ter-hero-one__content-container {
    margin-bottom: -65px !important;
  }
  .ter-list-feat-main__header {
    font-size: 26px !important;
    margin-bottom: 30px !important;
  }
  .webinar_video iframe {
    width: 100%;
    height: 200px;
  }
  .content_inner h1 {
    font-size: 6vw !important;
  }
  .luna-language-selector__bottom-section {
    margin-top: 0px !important;
  }
  .parallax {
    background-attachment: scroll;
  }
}

.ter-cta-section {
  padding: 25px;
}

.content_inner ul {
  font-size: 18px !important;
  line-height: 1.5 !important;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  margin: 0;
  word-break: break-word;
}

.ter-card .ter-button {
  word-break: break-word;
  height: auto;
  white-space: inherit;
  line-height: inherit;
  padding: 10px;
}

.ter-list-feat-four--theme-4 .ter-dumpling__icon-wrapper svg {
  fill: #f9f9fb!important;
}

.ter-list-feat-four--theme-4 .ter-dumpling__small-dash {
  border-top: 1px solid #f9f9fb!important;
}

.ter-list-feat-five--theme-1 .ter-icon-list-item {
  background-color: transparent!important;
}

.parallax {
  background-attachment: fixed;
  background-position: center center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
}

.ter-hero-four .ter-cta-section {
  padding-left: 0;
}
.display-block {
  display: block;
}
.display-none { 
  display: none;
}
.ter-list-feat-main--default {
  background: #fff !important;
}
.ter-list-feat-five--default {
  background: #fff !important;
}
.ter-list-feat-five--default .ter-icon-list-item {
  background: #fff !important;
}
.ter-navbar .ter-nav-mini-menu{
  height: 58px !important;
}
/* Firefox only: */
@-moz-document url-prefix() {
  .ter-navbar .ter-nav-mini-menu{
    height: 72px !important;
  }
}
/* Safari only: */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .ter-navbar .ter-nav-mini-menu{
      height: 72px !important;
    }
  }
}
.ter-navbar__wrapper .ter-navbar__nav-link p{
  height: 18px;
}
section a {
  color: #005f9e;
}