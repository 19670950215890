@import url("../../../assets/fonts/fonts.css");
* {
  box-sizing: border-box;
}
.imgResponsive {
  max-width: 100%;
}
.blog-cards {
  padding: 100px 30px;
}

.blog-cards h2 {
  font-family: "Open Sans", "Segoe UI";
  font-weight: 900;
  font-size: 32px;
  text-align: center;
  color: #333243;
  margin-bottom: 75px;
}
.blog-cards-container {
  max-width: 955px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.blog-cards-box {
  padding: 15px;
  width: 307px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* margin-left: 15px;
    margin-right: 15px; */
}
.blog-card-inner {
  flex-grow: 1;
}
.blog_cards_box .white_btn {
  width: 100%;
  text-align: center;
  flex-shrink: 0;
}
.blog-cards-box label {
  margin-top: 26px;
  text-transform: uppercase;
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  font-family: "Open Sans", "Segoe UI";
  display: block;
  margin: 20px 0px 6px 0px;
}
.blog-cards-box h4 {
  margin: 0px 0px 10px 0px;
  color: #333333;
  font-weight: 800;
  font-size: 18px;
  font-family: "Open Sans", "Segoe UI";
}

.blog-cards-box p {
  color: #333333;
  font-family: "Open Sans", "Segoe UI";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.feature-img{
  position: relative;
  height: 250px;
}

.white-btn {
  background-color: #0267b4;
  color: #fff;
  font-weight: 400;
  font-family: "Open Sans", "Segoe UI";
  font-size: 16px;
  height: 48px;
  line-height: calc(48px - (2px));
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-top: 10px;
}
.white-btn:hover {
  text-decoration: none;
}
.imgRespons {
  width: 100% !important;
  object-fit: cover !important;
  height: 100% !important;
  padding-bottom: 1rem;
}
.white-btn:hover {
  background-color: #005f9e;
  color: #fff;
}



@media (max-width: 1015px) {
  .blog-cards-box {
    width: 30%;
  }
}
@media (max-width: 899px) {
  .blog-cards-box {
    width: 100%;
    margin-bottom: 50px;
  }
  .ter-filter-hero__dropdown-container {
    margin-left: 32px !important;
  }
}
