.passageStops{
	background-color: black;
	mix-blend-mode: overlay;
	width: 33.3%;
	top: calc(1em + 15px);
}
#passage, .passageStops{ height: 10px; }
#passageWrapper, .passageStops {
	position: sticky; 
	position: -webkit-sticky; 
	z-index: 1; 
}
#passage{ background: violet; margin: 0 0 20px 0; filter: contrast(120%); }
#passageWrapper{
	background-color: white;
	height: 40px;
	top: 0px;
}
.passageStops:nth-child(4){ margin-left: 33.3%; }
.passageStops:nth-child(6){ margin-left: 66.6%; }


.sections{
	height: 400px; margin-bottom: 20px;
	text-align: center;
}






@supports not((position:sticky) or (position:-webkit-sticky)){
	body *:not(main){display: none;} 
	main::after{display: block; content:'THIS DEMO IS NOT SHOWN BECAUSE POSTION:STICKY IS NOT SUPPORTED IN YOUR BROWSER';  background-color: lightgrey; margin:auto; padding: 16px; }
	main::before{display: block; content:'🍿'}
}










