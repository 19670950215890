.luna__loading-image {
  display: block;
  margin: 0 auto;
  &-container {
    height: 100%;
    display: grid;
    align-items: center;
    min-height: 400px;
  }
}
