@import url("../../../assets/fonts/fonts.css");

.imgResponsive {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  max-width: 100%;
}
.peopleCard_container {
  padding: 100px 30px;
  background: #eaeaef;
}
.peopleCard_container h2 {
  font-family: "Open Sans", "Segoe UI";
  font-weight: 900;
  font-size: 34px;
  text-align: center;
  margin: 0px;
  color: #363545;
}
.peopleCard_inner {
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 55px;
  flex-wrap: wrap;
}
.people_card {
  width: 288px;
  background: #fff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
}
.people_card_body {
  padding: 32px 24px;
}
.people_card_body h4 {
  font-family: "Open Sans", "Segoe UI";
  font-weight: 900;
  font-size: 20px;
  margin: 0px;
  color: #363545;
}
.people_card_body p {
  font-family: "Open Sans", "Segoe UI";
  font-weight: 300;
  font-size: 17px;
  margin: 24px 0px;
  color: #363545;
}
.people_card_body ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  font-family: "Open Sans", "Segoe UI";
  font-weight: 400;
  color: #363545;
  font-size: 14px;
}

.people_card_body li {
  padding: 4px 0px;
  word-break: break-all;
}
.people_card_body ul li img {
  vertical-align: text-bottom;
  width: 20px;
  margin-right: 10px;
}

@media (max-width: 500px) {
  .people_card {
    margin: 20px 0px;
  }
}
