.navbar-toggler {
  background-color: white !important;
}

.navHeader {
  border-bottom: 2px solid gray;
}

.navbar {
  padding: 27px 0px !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.nav-link,
.nav-link:link {
  color: white !important;
}

.nav-link:hover {
  color: rgba(240, 240, 240, 0.7) !important;
}

.upa-logo {
  width: 80%;
}

.ter-mega-menu__nav-text {
  font-weight: 500 !important;
  margin-top: 12px !important;
}
// .ter-mega-menu {
//   height: 58px;
// }
.ter-mega-menu__dropdown {
  top: 64px;
}
.dropdown-menu a,
.dropdown-menu a:link,
.dropdown-menu a:hover {
  color: #212529 !important;
  font-weight: 500 !important;
}

.ter-navbar-top__search-wrapper {
  margin-bottom: 0;
}

.ter-navbar__nav-link {
  padding-bottom: 15px !important;
}

@media only screen and (max-width: 575px) {
  .logo {
    width: 90%;
  }

  .ter-navbar-top__division-name {
    font-size: 12px !important;
  }

  .jumboHome {
    margin-top: -185vh;
    height: 100%;
    background-position: 55% 50%;
    background-image: url(../../../src/assets/images/home-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .jumboContactus {
    height: 500px;
    background-position: 90% 50%;
  }
  .jumboSupport {
    height: 500px;
    background-position: 70% 50%;
  }
  .intro-h1 {
    color: white;
    margin-top: 200vh;
  }
  .see-more-arrow {
    padding-top: 100px;
  }
  .navHeader {
    border-bottom: 2px solid white;
  }

  a {
    font-size: 12px !important;
  }
}

.luna-region-card--is-active {
  background: black;
}
.ter-navbar-top__logo {
  padding: 0 !important;
  width: auto !important;
  max-height: 17px !important;
}

.ter-navbar-top__division-name {
  // font-size: 16px;
  width: 500px !important;
}

.ter-utility-nav__page-title {
  font-size: 14px !important;
  font-family: "Open Sans" !important;
  font-weight: 400 !important;
}

// .ter-navbar .ter-nav-mini-menu {
//   height: 70px !important;
// }

.ter-mobile-mega-menu-category__submenu {
  background: #d0d0d7;
  margin: 0;
  overflow: hidden;
  height: auto;
  max-height: 0;
  transition: 0.5s;
  padding: 0;
}
.ter-mobile-mega-menu-category__submenu:active {
  max-height: fit-content !important;
  height: fit-content !important;
}

.ter-navbar-top__search-wrapper {
  display: none;
}

@media (max-width: 600px) {
  .ter-navbar-top__logo {
    max-height: 18px !important;
    transform: none;
  }
  .ter-navbar-top__left {
    width: 270px!important;
  }

 .ter-navbar-top__left .ter-navbar-top__logo-link {
    max-width: 100%;;
  }
  .ter-navbar-top__right--mobile-hamburger {
    width: 30px;
  }
}

.ter-utility-nav .ter-button {
  width: auto!important;
}
.ter-navbar .ter-nav-mini-menu__drop-down a{
  font-weight: 500;
}
.ter-navbar .ter-nav-mini-menu__text{
  font-weight: 500;
}
.ter-navbar__nav-link p {
  font-weight: 500;
}