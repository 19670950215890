.date_box {
    color: #a7a7a7;
    font-size: 14px;
    font-family: "Open Sans", "Segoe UI";
    margin-top: -10px;
    display: block;
}
.date_box img {
    vertical-align: top;
    width: 14px;
    margin-right: 5px;
    margin-top: 2px;
}