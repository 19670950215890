.demo_form{ background: url('../../../assets/images/bg-img.jpg')no-repeat center bottom;     background-size: cover;}
.form_inner{    background: #fff;
  max-width: 500px;
  padding: 25px;}
  .form_inner h3{ text-align: center;}
  .form_inner p{    text-align: center;
    line-height: 22px;
    font-size: 18px;     padding-bottom: 30px;}
 
.form_s { margin: 0 -15px;}
  .clear { clear: both;}

  .form_s input[type=text]{width: 100%;     height: 35px;}
  .form_s label{ font-size: 16px;}

  .form_s textarea {
   
    display: block;
    width: 100%; height: 80px;
}

.form_s .col-h{     padding: 0px 15px 15px 15px;
  float: left;
  width: 50%;}
  .form_s .col-f{      padding: 0px 15px 15px 15px;
   }

.contact_sec2 .ter-list-feat-six {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  min-height: auto !important;
  padding: 0px !important;

  @media screen and (max-width: 900px) {
    display: block;
  }

  &__card {
    padding: 32px;
    margin: 0;
    width: 100%;
    background: #f3f3f7;
    min-width: 200px;
    min-height: 100px;
    vertical-align: center;
    display: flex;
    margin-bottom: 26px;
    img {
      width: 100%;
    }

    @media screen and (max-width: 900px) {
      margin: 0 auto 32px;
      max-width: 600px;
    }
  }

}
