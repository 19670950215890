

.ter-list-feat-seven {
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .ter-list-feat-main {
      padding: 96px calc((100vw - 1200px) / 2);
      margin: auto;
      position: relative;
      overflow: hidden;
  }
  
  .ter-hero__header, .ter-feat__header {
      font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"helvetica neue",helvetica,ubuntu,roboto,noto,"segoe ui",arial,sans-serif;
  }
  
  .ter-list-feat-main .ter-list-feat-main__header, .ter-list-feat-main .ter-list-feat-main__sub-header, .ter-list-feat-main .ter-list-feat-main__sub-header-holder {
      opacity: 1;
      transition: opacity 0.2s;
  }
  
  .ter-list-feat-main .ter-list-feat-main__header, .ter-list-feat-main .ter-list-feat-main__sub-header, .ter-list-feat-main .ter-list-feat-main__sub-header-holder {
      opacity: 1;
      transition: opacity 0.2s;
  }
  
  .ter-variable-card {
      position: relative;
      width: 288px;
      margin: 16px 16px 32px;
      z-index: 1;
      background: transparent;
      display: flex;
      flex-direction: column;
      box-shadow: none;
  }
  
  .luna-video-feat__thumbnail-container, .luna-video-feat .variable-content-img-container {
      position: relative;
  }
  
  .ter-list-feat-main .ter-variable-card .ter-variable-card__header, .ter-list-feat-main .ter-variable-card .ter-variable-card__text, .ter-list-feat-main .ter-variable-card .ter-button, .ter-list-feat-main .ter-variable-card .luna-video-feat__thumbnail {
      opacity: 1;
      transition: opacity 0.2s;
  }
  
  .ter-variable-card img {
      flex: 1 1;
      padding: 0;
  }
  
  .luna-video-feat__thumbnail, .luna-video-feat .variable-content-img {
      background-size: cover;
      height: 162px;
      flex: 1 1;
      vertical-align: top;
      width: 288px;
      margin: auto;
      transition: 0.2s;
  }
  
  .ter-variable-card__body {
      font-size: 16px;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 10px 0 0 0;
  }
  
  .luna-video-feat__video-wrapper--default {
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
  }
    
  .luna-video-feat__video-wrapper {
      position: relative;
      transition: 0.3s;
      transform: translateY(50px);
      z-index: 100;
  }
  
  h1, h2, h3, h4, h5, h6 {
      font-size: 1em;
      font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"helvetica neue",helvetica,ubuntu,roboto,noto,"segoe ui",arial,sans-serif;
      line-height: 1.2;
      font-weight: 800;
      color: #262533;
      display: block;
      margin: 0 0 0.67em 0;
      color: #363545;
  }
  
  .ter-list-feat-main .ter-variable-card .ter-variable-card__header, .ter-list-feat-main .ter-variable-card .ter-variable-card__text, .ter-list-feat-main .ter-variable-card .ter-button, .ter-list-feat-main .ter-variable-card .luna-video-feat__thumbnail {
      opacity: 1;
      transition: opacity 0.2s;
  } 
   
  .ter-variable-card__body p {
      flex: 1 1;
      font-size: 16px;
      display: flex;
  }
   
  .ter-hero__header, .ter-feat__header {
      font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"helvetica neue",helvetica,ubuntu,roboto,noto,"segoe ui",arial,sans-serif;
  }
  
//   .react-reveal {
//       text-align: center;
//   }

  .ter-search-bar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ter-search-bar__input {
    font-size: 16px;
    line-height: 48px;
    height: 48px;
    color: #363545; 
    padding: 0 50px 0 16px;
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #363545;
    background-color: #f9f9fb;
    border-radius: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.ter-search-bar__submit {
    font-size: 16px;
    line-height: 48px;
    height: 48px;
    color: #363545;
    padding: 0 16px;
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    border-width: 0;
    border-style: solid;
    border-color: #363545;
    background-color: transparent;
    border-radius: 0;
    width: initial;
    margin-left: 1px;
    position: absolute;
    right: 10px;
}

.text-left {
    text-align: left;
}

hr {
    width: 100%;
    height: 1px;
    background-color: black !important;
    border: 0;
}