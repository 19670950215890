
*{
    box-sizing: border-box;
} 
.imgResponsive{
    max-width: 100%;
}
.our_brands_container{
    max-width: 1055px;
    margin-left: auto;
    margin-right: auto;
}
.our_brands{
    padding: 100px 0px;
    text-align: center;
    background: #002d5b;
}
.our_brands h2{
    color: #fff;
    font-weight: 900;
    font-size: 34px;
    font-family: "Open Sans","Segoe UI";
    margin: 0 0 40px;
}
.our_brands p{
    color: #fff;
    font-size: 24px;
    font-family: "Open Sans","Segoe UI";
    font-weight: 300;
    margin: 0 0 60px;
}
.brands_logo{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.brands_logo div{
    width: 32%;
    margin: 20px 0px;
}

@media(max-width:767px){
    .brands_logo div{
        width: 100%;
    } 
}

@media(max-width:500px){
    .our_brands_container{        padding: 0 30px;    }
}
