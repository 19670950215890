.ter-list-feat-main__header{text-align: center;}
.resource_main .ter-button--secondary--1, .resource_main a.ter-button--secondary--1 {
  border: 2px solid #005f9e;
   background-color: #005f9e; color:#FFF!important;
}

.resource_main .ter-list-feat-six__card {    padding: 32px 24px;}
.resource_main .ter-feat__header{text-align: center;     padding-bottom: 75px;
  font-size: 34px;
  margin-bottom: 0;}

  .resource_main  h4{    font-size: 20px;
    margin-bottom: 20px; }
    .resource_main p{    font-size: 20px;font-size: 20px;font-family: 'Open Sans' !important;
}
.resource_main .ter-button{font-weight: 400;     margin-top: 30px; font-size:16px; padding: 0 40px;}

.ter-button,  a.ter-button {
  font-size: 16px;
  font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"helvetica neue",helvetica,ubuntu,roboto,noto,"segoe ui",arial,sans-serif;
  font-weight: 400;
  font-feature-settings: "kern" !important;
  -webkit-font-kerning: normal !important;
  font-kerning: normal !important;
  padding: 0 24px;
  height: 48px;
  line-height: calc(48px - (2px));
  border-radius: 0;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  text-align: center;
  display: inline-flex;
  align-items: center; 
}

.resource_main .ter-button:hover {
  background-color: #09466f; color:#FFF!important;
}

.ter-list-feat-six {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media screen and (max-width: 900px) {
    display: block;
  }

  &__card {
    padding: 32px;
    margin: 0;
    width: 100%;
    background: #f3f3f7;
    min-width: 200px;
    min-height: 100px;
    vertical-align: center;
    img {
      width: 100%;
    }

    @media screen and (max-width: 900px) {
      margin: 0 auto 32px;
      max-width: 600px;
    }
  }

}
