

.list-feat-custom {
	p {
		font-size: 16px;
	}
}
.ter-list-feat-main {
    padding: 100px calc((100vw - 1200px) / 2);
    margin: auto;
    position: relative;
    overflow: hidden;
}
.react-reveal {
    opacity: 0;
}
.ter-table {
    width: 100%;
    font-size: 16px;
    border-spacing: 0px;
}
.ter-table tr:nth-child(odd) td {
    background-color: rgba(0,0,0,0);
}
b, strong { 
    font-weight: bold;
}
.ter-table tr td {
    vertical-align: top;
    padding: 12px;
    font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"helvetica neue",helvetica,ubuntu,roboto,noto,"segoe ui",arial,sans-serif;
    font-weight: 100;
    font-feature-settings: "kern" !important;
    -webkit-font-kerning: normal !important;
    font-kerning: normal !important;
}

.ter-table tr:nth-child(even) td {
    background-color: #f3f3f7;
}

.ter-button, a.ter-button {
    font-size: 16px;
    font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"helvetica neue",helvetica,ubuntu,roboto,noto,"segoe ui",arial,sans-serif;
    font-weight: 400;
    font-kerning: normal !important;
    padding: 0 24px;
    height: 48px;
    line-height: calc(48px - (2px));
    border-radius: 0;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
    text-align: center;
    display: inline-flex;
    align-items: center;
}

.ter-button--primary--1:hover, a.ter-button--primary--1:hover {
    color: #f9f9fb;
    text-decoration: none;
    background-color: #0a5587;
    border-color: #0a5587;
    cursor: pointer;
}

.ter-button--primary--1, a.ter-button--primary--1 {
    background-color: #0d6aa8;
    border: 1px solid #0d6aa8;
    color: #f9f9fb;
    
}