.ter-list-feat-seven {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ter-list-feat-main {
  padding: 96px calc((100vw - 1200px) / 2);
  margin: auto;
  position: relative;
  overflow: hidden;
}

.ter-hero__header,
.ter-feat__header {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

.ter-list-feat-main .ter-list-feat-main__header,
.ter-list-feat-main .ter-list-feat-main__sub-header,
.ter-list-feat-main .ter-list-feat-main__sub-header-holder {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-list-feat-main .ter-list-feat-main__header,
.ter-list-feat-main .ter-list-feat-main__sub-header,
.ter-list-feat-main .ter-list-feat-main__sub-header-holder {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-variable-card {
  position: relative;
  width: 288px;
  margin: 16px 16px 32px;
  z-index: 1;
  background: transparent;
  display: flex;
  flex-direction: column;
  box-shadow: none;
}

.luna-video-feat__thumbnail-container,
.luna-video-feat .variable-content-img-container {
  position: relative;
}

.ter-list-feat-main .ter-variable-card .ter-variable-card__header,
.ter-list-feat-main .ter-variable-card .ter-variable-card__text,
.ter-list-feat-main .ter-variable-card .ter-button,
.ter-list-feat-main .ter-variable-card .luna-video-feat__thumbnail {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-variable-card img {
  flex: 1 1;
  padding: 0;
}

.luna-video-feat__thumbnail,
.luna-video-feat .variable-content-img {
  background-size: cover;
  height: 162px;
  flex: 1 1;
  vertical-align: top;
  width: 288px;
  margin: auto;
  transition: 0.2s;
}

.ter-variable-card__body {
  font-size: 16px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0 0 0;
}

.luna-video-feat__video-wrapper--default {
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.luna-video-feat__video-wrapper {
  position: relative;
  transition: 0.3s;
  transform: translateY(50px);
  z-index: 100;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-family: "Open Sans", "Open Sans", -apple-system,
    BlinkMacSystemFont, "helvetica neue", helvetica, ubuntu, roboto, noto,
    "segoe ui", arial, sans-serif;
  line-height: 1.2;
  font-weight: 800;
  color: #262533;
  display: block;
  margin: 0 0 0.67em 0;
  color: #363545;
}

.ter-list-feat-main .ter-variable-card .ter-variable-card__header,
.ter-list-feat-main .ter-variable-card .ter-variable-card__text,
.ter-list-feat-main .ter-variable-card .ter-button,
.ter-list-feat-main .ter-variable-card .luna-video-feat__thumbnail {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-variable-card__body p {
  flex: 1 1;
  font-size: 16px;
  display: flex;
}

.ter-hero__header,
.ter-feat__header {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

//   .react-reveal {
//       text-align: center;
//   }

.ter-card {
  background: #ffffff;
  position: relative;
  border-width: 0px;
  border-style: solid;
  border-color: #eaeaef;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  // -webkit-box-shadow: 0 4px 12px 0 rgb(54 53 69 / 10%), 0 12px 16px 4px rgb(54 53 69 / 5%);
  // box-shadow: 0 4px 12px 0 rgb(54 53 69 / 10%), 0 12px 16px 4px rgb(54 53 69 / 5%);
}

.ter-card__image {
  margin: 0px;
  padding: 0px;
}

.ter-card__image img {
  width: 100%;
  height: auto;
}

.ter-card__body {
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 15px;
  text-align: left;
}

.ter-card__header {
  font-size: 16px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  font-feature-settings: "kern" !important;
  font-kerning: normal !important;
  color: #0d6aa8;
  font-weight: normal;
}

.ter-card__sub-header {
  margin: 0;
  margin-bottom: 16px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  font-weight: 400;
  font-kerning: normal !important;
}

.ter-card__body p {
  font-size: 16px;
  color: #0d6aa8;
  margin-bottom: 20px !important;
}

.ter-button--primary--1,
a.ter-button--primary--1 {
  background-color: #0d6aa8;
  border: 1px solid #0d6aa8;
  color: #f9f9fb;
}

.ter-button,
a.ter-button {
  font-size: 16px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  font-weight: 400;
  font-kerning: normal !important;
  padding: 0 24px;
  height: 48px;
  line-height: calc(48px - (2px));
  border-radius: 0;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  text-align: center;  
  display: inline-flex;
  align-items: center;
}

.ter-button--primary--1,
a.ter-button--primary--1 {
  background-color: #0d6aa8;
  border: 1px solid #0d6aa8;
  color: #f9f9fb;
}

.ter-button--primary--1:hover,
a.ter-button--primary--1:hover {
  color: #f9f9fb;
  text-decoration: none;
  background-color: #0a5587;
  border-color: #0a5587;
  cursor: pointer;
}

.ter-card__body ul {
  margin: 0px;
  padding: 0px 0 0 20px;
}
