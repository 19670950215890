

.ter-list-feat-four {
  &__dumpling-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    .ter-feat-five__dumpling-wrapper {
      width: 200px;
      margin: 16px 16px 48px;
    }
  }

  .ter-dumpling {
    a {
      color: #363545;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

}

.ter-list-feat-four__dumpling-container {
    max-width: 700px;
}

.ter-list-feat-four__dumpling-container .dumpling-custom {
    background: #f3f3f7;
    padding: 10px 15px;
    // height: 70px;
    margin: 16px 10px 8px;
}

.ter-dumpling__icon-wrapper-custom {
  background: white;
  padding: 10px 15px;
  border-radius: 150px;
  margin-right: 10px;
  height: 50px;
  width: 50px;
}

.ter-dumpling__icon-wrapper-custom img {
  width: 20px;
}

.ter-dumpling-custom {
  display: flex;
  // align-items: center;
}

.ter-dumpling__text {
  font-size: 16px;
}