
*{
    box-sizing: border-box;
} 
.imgResponsive{
    max-width: 100%;
}
.footer_social{
    text-align: center;
}
.footer_social_container{
    max-width: 750px;
    min-height: 250px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.footer_social_container .footer_icon_box{
    width: 20%;
    padding: 50px 0px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
}
.footer_social_links{
    text-align: left;
    visibility: hidden;
    -webkit-transition: all 0.3s linear;-moz-transition: all 0.3s linear;-o-transition: all 0.3s linear;transition: all 0.3s linear;
    opacity: 0;

    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    text-align: center;
}
.footer_social_links ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
}
.social_img{
    -webkit-transition: all 0.3s linear;-moz-transition: all 0.3s linear;-o-transition: all 0.3s linear;transition: all 0.3s linear;
    opacity: 1;
}
.footer_social_links li{
    padding: 5px;
}
.footer_social_links a{
    color: #6a6976;
    font-size: 14px;
    font-family: "Open Sans","Segoe UI";
    text-decoration: none;
    font-weight: 500;
}
.footer_social_links a:hover{
    color: #0067b5;
}
.footer_icon_box:hover .social_img{
    visibility: hidden;
    opacity: 0;
}
.footer_icon_box:hover .footer_social_links{
    visibility: visible;
    opacity: 1;
}

@media(max-width:767px){
    .social_img {
        width: 30px;
    }
    .footer_social_container .footer_icon_box {
        padding: 30px 0px;
    }
}

@media(max-width:600px){
    .footer_social_container {
        padding: 0;
    }
    .footer_social_links {
        min-width: 125px;
    }
    .footer_social_container .footer_icon_box {
        width: 25%;
    }
}