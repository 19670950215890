.ter-list-feat-seven {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ter-list-feat-main {
  padding: 96px calc((100vw - 1200px) / 2);
  margin: auto;
  position: relative;
  overflow: hidden;
}

.ter-hero__header,
.ter-feat__header {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

.ter-list-feat-main .ter-list-feat-main__header,
.ter-list-feat-main .ter-list-feat-main__sub-header,
.ter-list-feat-main .ter-list-feat-main__sub-header-holder {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-list-feat-main .ter-list-feat-main__header,
.ter-list-feat-main .ter-list-feat-main__sub-header,
.ter-list-feat-main .ter-list-feat-main__sub-header-holder {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-variable-card {
  position: relative;
  width: 288px;
  margin: 16px 16px 32px;
  z-index: 1;
  background: transparent;
  display: flex;
  flex-direction: column;
  box-shadow: none;
}

.card-image-container,
.luna-video-feat .variable-content-img-container {
  position: relative;
}

.ter-list-feat-main .ter-variable-card .ter-variable-card__header,
.ter-list-feat-main .ter-variable-card .ter-variable-card__text,
.ter-list-feat-main .ter-variable-card .ter-button,
.ter-list-feat-main .ter-variable-card .card-image {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-variable-card img {
  flex: 1 1;
  padding: 0;
}

.card-image,
.luna-video-feat .variable-content-img {
  background-size: cover;
  max-height: 162px;
  flex: 1 1;
  vertical-align: top;
  max-width: 288px;
  margin: auto;
  transition: 0.2s;
}

.ter-variable-card__body {
  font-size: 16px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0 0 0;
}

.luna-video-feat__video-wrapper--default {
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.luna-video-feat__video-wrapper {
  position: relative;
  transition: 0.3s;
  transform: translateY(50px);
  z-index: 100;
}

.ter-list-feat-main .ter-variable-card .ter-variable-card__header,
.ter-list-feat-main .ter-variable-card .ter-variable-card__text,
.ter-list-feat-main .ter-variable-card .ter-button,
.ter-list-feat-main .ter-variable-card .card-image {
  opacity: 1;
  transition: opacity 0.2s;
}

.ter-variable-card__body p {
  flex: 1 1;
  font-size: 16px;
  display: flex;
}

.ter-hero__header,
.ter-feat__header {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

.allwebinarlist .ter-variable-card__body {
  font-size: 16px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0 0 0;
  text-align: left;
}

.allwebinarlist .ter-variable-card__body p {
  margin-bottom: 20px !important;
}

.ter-list-feat-main
  .allwebinarlist
  .ter-variable-card
  .card-image {
  margin-bottom: 25px !important;
}

.allwebinarlist .ter-button--primary--1,
.allwebinarlist a.ter-button--primary--1 {
  background-color: #0d6aa8;
  border: 1px solid #0d6aa8;
  color: #f9f9fb !important;
  justify-content: center;
  font-size: 16px !important;
  margin-bottom: 0px !important;
}

.ter-list-feat-main .allwebinarlist .ter-list-feat-main__header {
  color: #363545;
  margin-bottom: 75px;
}

.ter-pagination__list {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0px;
}

.ter-pagination__list-item {
  padding: 14px 20px;
  font-size: 14px;
  background: #f3f3f7;
  margin: 0px 3px;
  color: #0e3844;
  cursor: pointer;
}

.ter-pagination__list-item.is-active {
  background: #005f9e;
  color: #ffffff;
}

.ter-pagination__list-item.ter-pagination__list-item--previous {
  opacity: 0.5;
}

.ter-icon--8px {
  width: 8px;
  height: 8px;
}

.mt20 {
  margin-top: 20px;
}

.ter-list-feat-main .ter-variable-card .ter-button {
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.ter-card__text{
  width: 100% !important;
justify-content: center;
}
.ter-variable-card{
  text-align: center;
}
