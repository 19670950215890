
.home_tabs_box_main {
  background: white;
}

.home_tabs_box_main .home_tab_section {
  background: #eaeaef;
  z-index: 9999;
    position: relative;
}
.home_tabs_box_main .home_tab button.active {
  background-color: #eaeaef;
}
.home_tabs_box_main .tab_body {
  display: block !important;
}
.home_tabs_box_main .home_tabs_box {
  background: #f9f9fb;
  padding: 30px;
  margin-bottom: 30px;
}

.home_tabs_box_main h1 {
  font-weight: 700;
  font-size: 28px;
  font-family: "Open Sans" !important;
  line-height: 1.2;
  color: #262533;
  display: block;
  margin: 0 0 50px 0;
  color: #363545;
}

.home_tabs_box_main .home_tabs_box h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 7px 0;
}

.home_tabs_box_main .home_tabs_box p {
  color: #848484;
  margin-bottom: 0px;
  font-size: 20px;
  display: inline-block;
  margin-right: 5px;
}
.home_tabs_box_main .home_tabs_box span {
  font-size: 20px;
  color: #005f9e;
}
.home_tabs_box h2, .home_tabs_box span{
  cursor: pointer;
}
.home_tabs_box_main .home_tab li button{
  max-width: 220px;
}
