.contact_sec h3{ font-size: 24px;     margin: 40px 0 5px 0;}
.contact_sec h4{ font-size:18px; color:#0267b4;     margin-top:16px;}
.contact_sec .ter-list-feat-six__card { padding:12px !important;}
.contact_sec  .ter-list-feat-six__card{ font-size: 16px; line-height: 25px;     min-height: auto  !important;}
.contact_sec p{ font-size: 16px; line-height: 25px; margin-top: 15px;}
.contact_sec a{ color: #005f9e;}

.contact_sec{
  background: #fff;
}

.pad{
  padding: 100px 30px;
  max-width: 900px;
    margin: 0 auto;
}
.contact_sec .ter-list-feat-six {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  min-height: auto !important;
  padding: 0px !important;

  @media screen and (max-width: 900px) {
    display: block;
  }

  &__card {
    padding: 32px;
    margin: 0;
    width: 100%;
    background: #f3f3f7;
    min-width: 200px;
    min-height: 100px;
    vertical-align: center;
    img {
      width: 100%;
    }

    @media screen and (max-width: 900px) {
      margin: 0 auto 32px;
      max-width: 600px;
    }
  }

}
