@import url("../../../assets/fonts/fonts.css");

.imgResponsive{
    max-width: 100%;
}
.blog_listing{
    padding: 100px 30px;
    padding-bottom: 0px;
}

.blog_listing h2{
    margin: 0px 0px 60px 0px;
    font-size: 2rem;
    text-align: center;
}
.blog_cards h2{
    font-family: 'Open Sans', 'Segoe UI';
    font-weight: 900;
    font-size: 28px; 
    text-align: center;
    color: #fff;
    margin-bottom: 75px;
}
.blog_cards_container{
    max-width: 955px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.blog_cards_box{
    width: calc(33% - 28px);
    color: #fff;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
    margin-bottom: 47px;
    margin-left: 15px;
    margin-right: 15px;
}
.blog_card_inner{
    flex-grow: 1;
}
.blog_cards_box .white_btn {
    width: 100%;
    text-align: center;
    flex-shrink: 0;
}
.blog_cards_box label{
    margin-top: 26px;
    text-transform: uppercase;
    color: #005f9e;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Open Sans', 'Segoe UI';
    display: block;
    margin:0px 0px 6px 0px;
    word-break: break-word;
}
.blog_cards_box h4{
    margin: 0px 0px 20px 0px;
    color: #363545;
    font-weight: 900;
    font-size: 20px;
    font-family: 'Open Sans', 'Segoe UI';
    word-break: break-word;
}
.blog_cards_box p{
    color: #363545;
    font-family: 'Open Sans', 'Segoe UI';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 6;
   -webkit-box-orient: vertical;
  margin-bottom: 40px !important;
  word-break: break-word;
}
.blog_card_inner_body{
    padding: 32px 25px 0px 25px;
}
.blue_border_btn{
    display: inline-block;
    background-color: #fff;
    border: solid 1px #005f9e;
    color: #005f9e;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 400;
    font-family: 'Open Sans', 'Segoe UI';
    font-size: 16px;
    height: 32px;
    line-height: calc(32px - (2px));
    text-align: center; 
    text-decoration: none;
    margin: 0px 25px 32px 25px;
    text-decoration: none;
}
.blue_border_btn:hover{
    background-color: #005f9e;
    color: #fff;
    text-decoration: none;
}
.date_box {
    margin-bottom: 8px;
}

.navigation_links {
    justify-content: center;
    padding: 20px 50px 50px 50px!important;
    display: flex;
    padding: 0px;
    list-style: none;
    font-family: 'Open Sans', 'Segoe UI';
    font-weight: 400;
}

.navigation_links li a {
    display: inline-block;
    padding: 10px 20px;
    background: #f3f3f7;
    color: #363545;
    margin: 1px;
    text-align: center;
    text-decoration: none;
}
.navigation_links .active a {
    background: #005f9e;
    color: #fff;
}
.navigation_links li a:hover {
    background: #005f9e;
    color: #fff !important;
}
.feature_blog_box{
    max-width: 955px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.feature_blog_box .blog_cards_box{
    width: calc(50% - 30px);
}
.feature_img{
    position: relative;
    height: 250px;
}
.feature_img img{
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
}
.feature_blog_box .blog_cards_box .feature_label{
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    width: 115px;
    height: 30px;
    right: 0px;
    bottom: 0px;
    background: #005f9e;
    text-align: center;
    padding: 7px 0px;
    margin: 0px;
    line-height: 17px;
}

.header_filter_box{
    background:#002D5B;
}
.header_filter_container{
    padding: 0 calc((100vw - 1200px) / 2) 48px;
}
.blog_filter_header{
    max-width: 500px;
    padding: 100px 0 100px 100px;
}
.blog_filter_header h3{
    color: #fff;
    font-size: 28px;
    margin: 0;
    line-height: 1.2;
    font-weight: 500;
    font-family: 'Open Sans', 'Segoe UI';
}
.blog_filter_header p{
    font-size: 20px;
    padding-top: 12px;
    margin: 0;
    color: #fff;
    font-family: 'Open Sans', 'Segoe UI';
    line-height: 1.6;
    font-weight: 300;
}
.filter_area{
    margin-left: 100px;
    display: flex;
    flex-wrap: wrap;
}
.filter_area label{
    color: #fff;
    display: block;
    margin-bottom: 0;
    font-family: 'Open Sans', 'Segoe UI';
    font-weight: 400;
    font-size: 16px;;
}
.filter_area select, .filter_area input[type="text"] {
    font-size: 16px;
    line-height: 1.15;
    margin: 0;
    height: 45px;
    color: #363545; 
    padding: 0 16px;
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #363545;    
    border-radius: 0; 
    border: 0;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    outline: 0;
    background-color: #f9f9fb;
    margin-top: 5px;
}
.filter_area div{
    min-width: 276px;
    padding-right: 20px;
    position: relative;
}
.filter_search svg{
    position: absolute;
    right: 31px;
    top: 50px;
    z-index: 2;
}








@media(max-width:768px){
    .blog_cards_box {        width: calc(50% - 30px);    }
}
@media(max-width:767px){
    .blog_cards_box {        width: 100%; margin-left: 0px;  margin-right: 0px;  }
    .blog_filter_header {        padding: 32px;    }
    .filter_area {        margin:0px 32px;    }
    .filter_area div {        min-width: 100%;        padding-right: 0px;    }
    .filter_search svg {        right: 12px;    }
    .filter_select svg {        right: 12px;    }
    .filter_area div{margin-bottom: 12px;}
}
@media(max-width:500px){
    .blog_cards_box{        width: 100%;  }
    .feature_blog_box .blog_cards_box{        width: 100%;    }
}
