input[type="text"],
textarea,
input[type="password"],
input[type="email"] {
  font-size: 16px;
  line-height: 48px;
  height: 45px;
  color: #363545;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #363545;
  background-color: #f9f9fb;
  border-radius: 0;
  box-sizing: border-box;
}
input[type="text"]:focus,
textarea:focus,
input[type="password"]:focus,
input[type="email"]:focus {
  outline: none;
  border-color: #005f9e;
}
select {
  background: #f9f9fb url("../../../assets/icon/arrow.svg") right no-repeat !important;
  font-size: 16px !important;
  line-height: 42px !important;
  height: 46px !important;
  color: #363545 !important;
  box-sizing: border-box !important;
  padding: 0 36px 0 16px !important;
  position: relative !important;
  z-index: 1 !important;
  display: block !important;
  width: 100% !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #363545 !important;
  border-radius: 0 !important;
  box-sizing: border-box !important;
}
select:focus {
  outline: none !important;
  border-color: #005f9e !important;
}

input[type="submit"] {
  font-size: 16px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  font-weight: 400;
  font-feature-settings: "kern" !important;
  font-kerning: normal !important;
  padding: 0 35px;
  height: 50px;
  line-height: calc(48px - (2px));
  border-radius: 0;
  text-decoration: none;
  white-space: nowrap;
  margin-top: 20px;
  vertical-align: middle;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  text-align: center;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  color: #f9f9fb;
  text-decoration: none;
  background-color: #0a5587;
  border: solid 1px #0a5587;
}
input[type="submit"]:hover {
  background-color: #09466f;
  border: solid 1px #09466f;
}
.form_contact_box textarea {
  height: 135px;
}
label {
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
}

.form_box {
  background: #f3f3f7;
  padding: 100px 0px;
}
.form_box h2 {
  font-size: 34px;
  text-align: center;
}
.form_box h4 {
  font-size: 24px;
  text-align: center;
  font-weight: 100;
  margin-bottom: 75px;
}
.form_contact_box {
  max-width: 550px;
  margin: 0 auto;
}

.newsletter {
  padding: 100px 0;
}
.newsletter h2 {
  color: #fff;
  text-align: center;
  padding-bottom: 50px;
}
.newsletter .ter-filter-hero__dropdown-container {
  max-width: 662px !important;
  margin: 0 auto !important;
}
.newsletter .ter-button {
  background-color: #fff;
  border: 1px solid #0d6aa8;
  color: #0d6aa8 !important;
  padding: 0 29px;
  height: auto !important;
  cursor: pointer;
  margin-top: 25px;
  line-height: 40px;
}

.newsletter .ter-button:hover {
  background-color: #0d6aa8;
  border: 1px solid #0d6aa8;
  color: #fff !important;
}
.newsletter input[type="text"],
.newsletter input[type="email"] {
  height: 41px;
}

.ter-filter-hero {
  &__header-section {
    max-width: 500px;
    padding: 100px 0 100px 100px;

    @media (max-width: 500px) {
      padding: 100px 32px;
    }
  }

  &__header {
    @include header-standard-size;
    margin: 0;
  }

  &__text,
  &__text-holder p {
    font-size: font-size("base");
    padding-top: 12px;
    margin: 0;
  }

  &__dropdown-container {
    margin-left: 100px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @media (max-width: 500px) {
      margin-left: 32px;
    }
  }

  &__checkbox-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__dropdown-label {
    margin-bottom: 0;
    @include benton-medium();
    font-size: font-size("sm");
  }

  .ter-select {
    &__wrapper {
      width: 256px;
      margin-right: 20px;
    }

    @media (max-width: 500px) {
      margin: 0 0 20px 0 !important;
    }

    &__options-list {
      top: 50px;
    }
  }

  .ter-checkbox {
    margin: 0 20px;
    -ms-flex-item-align: end;
    align-self: flex-end;

    &__label {
      font-size: font-size("micro");
      padding-top: 3px;

      @media (max-width: 500px) {
        margin: 0 !important;
      }
    }
  }
}
