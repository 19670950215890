

.custom-feat {
  padding: 100px calc((100vw - 1200px) / 2);
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  background: #0d6aa8;
  position: relative
  
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.ter-hero__header, .ter-feat__header {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif;
  font-size: 28px;
}


@media (max-width: 767px) {
    .custom-feat iframe {
        width: 90%;
    }
    .custom-feat video {
      width: 90%;
  }
}