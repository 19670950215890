.ter-list-feat-main__header{text-align: center;}
.map_add .ter-icon-list-item { padding-top: 25px;}
.map_add iframe{ width: 100%; height: 280px; border:0;} 
.map_add .ter-list-feat-six__card{ background: #fff; padding: 0;}
.map_add p{    font-size: 18px;
  line-height: 22px; }
.ter-icon-list-item {
  display: flex;
}
.ter-icon-list-item__icon-wrapper {
  margin-right: 16px;
}

.ter-button,  a.ter-button {
  font-size: 16px;
  font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"helvetica neue",helvetica,ubuntu,roboto,noto,"segoe ui",arial,sans-serif;
  font-weight: 400;
  font-feature-settings: "kern" !important;
  -webkit-font-kerning: normal !important;
  font-kerning: normal !important;
  padding: 0 24px;
  height: 48px;
  line-height: calc(48px - (2px));
  border-radius: 0;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  text-align: center;
  display: inline-flex;
  align-items: center; 
}

.resource_main .ter-button:hover {
  background-color: #09466f; color:#FFF!important;
}

.ter-list-feat-six {
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media screen and (max-width: 900px) {
    display: block;
  }

  &__card {
    padding: 32px;
    margin: 0;
    width: 100%;
    background: #f3f3f7;
    min-width: 200px;
    min-height: 100px;
    vertical-align: center;
    img {
      width: 100%;
    }

    @media screen and (max-width: 900px) {
      margin: 0 auto 32px;
      max-width: 600px;
    }
  }

}


