.custom-modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease;
  
    &.visible {
        visibility: visible;
        opacity: 1;
    }
  
    &.hidden {
        visibility: hidden;
        opacity: 0;
    }
  
    .modal-content {
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        width: 43%; // Adjust as needed
        max-width: 600px; // Adjust as needed for the maximum width
        height: auto;
        max-height: 100%;
        overflow: hidden;
  
        @media (min-width: 768px) {
            width: 60%; // Adjust for larger screens
            max-width: 800px; // Adjust maximum width accordingly
            // max-height: 60%;
        }
  
        @media (min-width: 992px) {
            width: 43%; // Adjust for larger screens
            max-height: 80%;
            max-width: 1000px; // Adjust maximum width accordingly
        }
  
        @media (min-width: 1200px) {
            width: 43%; // Adjust for larger screens
            max-height: 80%;
            max-width: 1000px; // Adjust maximum width accordingly
        }
  
        @media (min-height: 600px) {}
    }
  
    .close-button {
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 4px;
        font-size: 20px;
        color: #333;
        padding: 0;
    }
  
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  
    .modal-image {
        width: 100px; // Adjust as needed
        height: 100px; // Adjust as needed
        object-fit: cover;
        margin-bottom: 10px;
    }
  
    .modal-heading {
        font-size: 1.3em;
        font-weight: 600;
        margin-bottom: 18px;
        text-align: center;
    }
  
    .modal-paragraph {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
    }
  
    .contact-button {
        background-color: #007bff;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        // width: 30%;
        margin: auto;
        cursor: pointer;
        font-size: 16px;
        margin-top: 15px;
        transition: background-color 0.3s ease;
  
        &:hover {
            background-color: #0056b3;
        }
    }
  }
  
  
  
  