.luna-search-page {
  &-search-again {
    text-align: center;
    padding: 0 calc((100vw - 1200px) / 2) 12px;
    margin: 0;

    @media screen and (max-width: 1272px) {
      padding: 0 36px 12px;
    }
  }

  &-search-results {
    padding: 0 calc((100vw - 1200px) / 2) 48px;

    @media screen and (max-width: 1272px) {
      padding: 0px 36px 48px;
    }

    @media screen and (max-width: 500px) {
      padding: 0px 12px 48px;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    padding-bottom: 64px;
  }

  .ter-card {
    width: 300px;
    margin: 16px 16px;
  }

  &--search {
    .ter-hero-2 {
      max-width: 100vw;
      &__header {
        margin: 0;
      }
    }

    .ter-input {
      display: block;
      margin-right: 32px;

      @media screen and (max-width: 600px) {
        max-width: 90vw;
        margin: auto;
      }
    }
  }

  &-form {
    padding-bottom: 64px;
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: 600px;

    @media screen and (max-width: 600px) {
      display: block;
    }

    .ter-button {
      display: block;
      margin-left: 32px;

      @media screen and (max-width: 600px) {
        margin: 16px auto;
      }
    }
  }

  .one-con-phase-feat__mobile-container {
    .ter-card {
      @media screen and (max-width: 450px) {
        width: auto !important;
      }
    }

    .ter-hero-2__container {
      @media screen and (max-width: 450px) {
        padding: 16px;
      }
    }
  }
}
