.carousel .control-dots .dot {
  transform: scale(1.1);
  transition: opacity 0.25s ease-in !important;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: none !important;
  background: #005f9f !important;
  border-radius: 50% !important;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.carousel .slide img {
  width: 15% !important;
}

.carousel .slider-wrapper {
  background: #ebebf0;
}
