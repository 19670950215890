/* @import url("../fonts/fonts.css"); */
.events_container {
  padding: 70px 30px;
  max-width: 990px;
  margin: 0 auto;
}
.event_list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.event_list li {
  padding: 30px 0px;
  border-bottom: solid 1px #dbdbdb;
}
.calendar_box {
  width: 127px;
  height: 155px;
  margin-right: 25px;
  text-align: center;
  color: #fff;
  background: #005f9e;
  text-transform: uppercase;
  font-family: "Open Sans", "Segoe UI";
}
.cal_month {
  font-size: 16px;
  padding: 6px 0px;
  background: #004f84;
  font-weight: 400;
}
.cal_date {
  font-size: 60px;
  font-weight: 900;
}
.cal_year {
  margin-top: -8px;
  font-size: 16px;
}
.calendar_category {
  font-size: 16px;
  font-weight: 400;
  color: #005f9e;
  font-family: "Open Sans", "Segoe UI";
  margin-top: 4px;
  display: block;
}
.calendar_header {
  display: flex;
  margin: 5px 0px 5px 0px;
}
.calendar_header h4 {
  font-size: 26px;
  font-weight: 900;
  color: #363545;
  font-family: "Open Sans", "Segoe UI";
  margin: 0px;
}
.calendar_header label {
  color: #fff;
  font-family: "Open Sans", "Segoe UI";
  font-weight: 400;
  border-radius: 5px;
  padding: 6px 25px;
  margin-left: 20px;
  font-size: 16px;
}
.calendar_detail {
  font-size: 24px;
  font-weight: 300;
  color: #005f9e;
  font-family: "Open Sans", "Segoe UI";
  margin: 0px;
}
.add_to_calendar {
  font-size: 18px;
  font-weight: 400;
  color: #005f9e;
  font-family: "Open Sans", "Segoe UI";
}
.add_to_calendar:hover {
  text-decoration: none;
}
.feature_calendar .calendar_header label {
  background: #009ada;
}
.feature_calendar .calendar_box {
  background: #009ada;
}
.feature_calendar .cal_month {
  background: #0071b0;
}
.highlighted_calendar .calendar_header label {
  background: #005f9e;
}
.pagination_calendar {
  justify-content: center;
    margin-top: 10px;
    display: flex;
    padding: 0px;
    list-style: none;
    font-family: "Open Sans", "Segoe UI";
    font-weight: 400;
    margin-bottom: 100px;
}
.pagination_calendar li a {
  display: inline-block;
  padding: 10px 20px;
  background: #f3f3f7;
  color: #363545;
  margin: 1px;
  text-align: center;
  text-decoration: none;
}
.pagination_calendar li a:hover {
  background: #005f9e;
  color: #fff !important;
}
.pagination_calendar .active a {
  background: #005f9e;
  color: #fff;
}
.pagination {
  display: inline-block;
  padding: 10px 20px;
  background: #f3f3f7;
  color: #363545;
  margin: 1px;
  text-align: center;
  text-decoration: none;
}
.pagination:hover {
  background: #005f9e;
  color: #fff;
}
.pafination-active {
  background: #005f9e;
  color: #fff;
}
.event_add_to {
  display: flex;
  align-items: center;
}
.event_add_to a {
  margin: 0px 5px;
}
.event_add_to span {
  font-size: 18px;
  font-weight: 400;
  color: #363545;
  margin-right: 5px;
}
.calendar_header h4 a {
  color: #363545;
}
/* .calendar_header h4:hover {
  color: #005f9e;
  text-decoration: underline;
} */
.details_btn{
  text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    font-style: italic;
    display: block;
    color: #0056b3;
}
@media (max-width: 899px) {
  .event_list li {
    flex-wrap: wrap;
    justify-content: center;
  }
  .calendar_detail {
    width: 100%;
    text-align: center;
  }
  .feature_calendar {
    text-align: center;
  }
  .calendar_header {
    justify-content: center;
    flex-wrap: wrap;
  }
  .calendar_header h4 {
    width: 100%;
  }
  .calendar_header label {
    margin-left: 0px;
  }
  .calendar_box {
    margin-right: 0px;
  }
}

@media (max-width: 600px) {
  .event_add_to {
    justify-content: center;
  }
  .register-button-column {
    justify-content: center;
    padding-top: 20px;
    
  }
}
