.ter-feat6__content {
  text-align: left !important;
  margin: 32px 0px !important;
}

.resource_main .ter-button--secondary--1,
a.ter-button--secondary--1 {
  border: 2px solid #005f9e;
  color: #005f9e !important;
}
