@font-face {
    font-family: 'BentonSansW05';
    src: url('BentonSansW05-Light.woff2') format('woff2'),
        url('BentonSansW05-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'BentonSansW05';
    src: url('BentonSans-Regular.woff2') format('woff2'),
        url('BentonSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'BentonSansW05';
    src: url('BentonSansW05-Medium.woff2') format('woff2'),
        url('BentonSansW05-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'BentonSansW05';
    src: url('BentonSansW05-Black.woff2') format('woff2'),
        url('BentonSansW05-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'BentonSansW05-Book';
    src: url('BentonSansW05-Book.woff2') format('woff2'),
        url('BentonSansW05-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}







