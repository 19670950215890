.content_social {
  padding: 100px 0;
  background-color: #fff;
}
.blog_detail_page h1,
.blog_detail_page h2,
.blog_detail_page h3,
.blog_detail_page h4,
.blog_detail_page h5,
.blog_detail_page h6,
.content_inner p .blog_detail_page p {
  word-break: break-word;
}

.content_inner {
  display: flex;
  margin: 0 auto;
  padding: 0px 30px;
}

.content_inner,
.content {
  max-width: 1140px !important;
}
.social_icon {
  margin-right: 25px !important;
  padding-top: 5px;
  position: relative;
}

.social_icon a {
  padding: 5px;
  display: block;
}
.social_icon button svg {
  width: 36px;
  height: 36px;
}
.social_icon ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.content_inner p {
  @include benton-normal;
  @include paragraph-type-setting;
  margin-bottom: 0;
  word-break: break-word;
}

.ter-filter-hero {
  &__header-section {
    max-width: 500px;
    padding: 100px 0 100px 100px;

    @media (max-width: 500px) {
      padding: 100px 32px;
    }
  }

  &__header {
    @include header-standard-size;
    margin: 0;
  }

  &__text,
  &__text-holder p {
    font-size: font-size("base");
    padding-top: 12px;
    margin: 0;
  }

  &__dropdown-container {
    margin-left: 100px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @media (max-width: 500px) {
      margin-left: 32px;
    }
  }

  &__checkbox-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__dropdown-label {
    margin-bottom: 0;
    @include benton-medium();
    font-size: font-size("sm");
  }

  .ter-select {
    &__wrapper {
      width: 256px;
      margin-right: 20px;
    }

    @media (max-width: 500px) {
      margin: 0 0 20px 0 !important;
    }

    &__options-list {
      top: 50px;
    }
  }

  .p-10 {
    padding: 5px;
  }

  .ter-checkbox {
    margin: 0 20px;
    -ms-flex-item-align: end;
    align-self: flex-end;

    &__label {
      font-size: font-size("micro");
      padding-top: 3px;

      @media (max-width: 500px) {
        margin: 0 !important;
      }
    }
  }
}
@media (max-width: 600px) {
  .social_icon {
    margin-right: 8px;
    margin-left: 0px;
  }
  .blog_detail_page .content {
    padding-left: 0px;
  }

  .blog_detail_page .content iframe {
    width: 100%;
    height: 300px;
  }
  .content_inner {
    flex-direction: column;
  }
  .social_icon {
    margin-right: auto!important;
    padding-bottom: 10px;
  }
  .social_icon .fixed_box {
    display: flex;
  }
}

.blog_detail_page .content img {
  max-width: 100% !important;
  height: auto;
}

.tag {
  color: #0267b4;
}
