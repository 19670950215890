.coments_ts{         padding: 50px 20px; background: #F4F4F4;}
.coments_ts p {      font-size: 18px;
  line-height: 23px;
  padding-top: 11px;}
  .coments_ts .date{ font-size: 16px;
    line-height: 28px;     color: #a7a7a7;}

.coments_ts .inner_com{ margin: 0 auto; max-width: 1200px;}
ul.comments li img.avatar {
  height: 80px;
  width: 80px;
}
.blog_comment_box h1 {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
}
ul.comments {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.comments li {
  clear: both;
  padding: 10px 0 0 115px;
}
ul.comments li .comment {
  margin-bottom: 10px;
}
.img-thumbnail {
  border-radius: 4px;
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
ul.comments li .img-thumbnail {
  margin-left: -115px;
  position: absolute;
}
ul.comments li img.avatar {
  height: 80px;
  width: 80px;
}
ul.comments .comment-block {
  background: #fff;
  border-radius: 5px;
  padding: 20px 20px 30px;
  position: relative;
}
ul.comments .comment-arrow {
  border-bottom: 15px solid transparent;
  border-right: 15px solid #fff;
  border-top: 15px solid transparent;
  height: 0;
  left: -15px;
  position: absolute;
  top: 28px;
  width: 0;
}
ul.comments .comment-block .comment-by {
  display: block;
  font-size: 1em;
  line-height: 21px;
  margin: 0;
  padding: 0px;
}

.ter-filter-hero {
  &__header-section {
    max-width: 500px;
    padding: 100px 0 100px 100px;

    @media (max-width: 500px) {
      padding: 100px 32px;
    }
  }

  &__header {
    @include header-standard-size;
    margin: 0;
  }

  &__text,
  &__text-holder p {
    font-size: font-size("base");
    padding-top: 12px;
    margin: 0;
  }

  &__dropdown-container {
    margin-left: 100px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @media (max-width: 500px) {
      margin-left: 32px;
    }
  }

  &__checkbox-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__dropdown-label {
    margin-bottom: 0;
    @include benton-medium();
    font-size: font-size("sm");
  }

  .ter-select {
    &__wrapper {
      width: 256px;
      margin-right: 20px;
    }

    @media (max-width: 500px) {
      margin: 0 0 20px 0 !important;
    }

    &__options-list {
      top: 50px;
    }
  }

  .ter-checkbox {
    margin: 0 20px;
    -ms-flex-item-align: end;
    align-self: flex-end;

    &__label {
      font-size: font-size("micro");
      padding-top: 3px;

      @media (max-width: 500px) {
        margin: 0 !important;
      }
    }
  }
}
